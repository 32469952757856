var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info)?_c('div',{staticClass:"list dfc cross-center"},[(_vm.list.length)?_c('div',_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('help-card',{attrs:{"meetingId":_vm.id,"userInfo":_vm.userInfo,"info":item,"type":_vm.type + '',"status":_vm.info.status}})],1)}),0):_c('empty'),(_vm.info.status != 5)?_c('div',{staticClass:"dfr cross-center main-between help-buttom-b"},[(
        !_vm.isHelped &&
          _vm.info.status != 5 &&
          _vm.info.status != 2 &&
          (_vm.type == 1 || _vm.type == 2)
      )?_c('div',{staticClass:"dfc flex-center",on:{"click":_vm.helpSubmit}},[_c('icy-button',{attrs:{"height":"0.8rem","border-radius":"0.44rem","width":[
            _vm.type == 1 && _vm.info.status != 5 && _vm.info.status != 2 && !_vm.isHelped
              ? '3rem'
              : '6.78rem'
          ][0]}},[_vm._v("登记我的帮助内容")]),_c('icy-iphone')],1):_vm._e(),_c('icy-bottom'),(_vm.type == 1 && _vm.info.status != 5)?_c('div',{staticClass:"dfc flex-center",on:{"click":_vm.openQrcodePopup}},[_c('icy-button',{attrs:{"height":"0.8rem","border-radius":"0.44rem","width":[_vm.isHelped || _vm.info.status == 2 ? '6.78rem' : '3rem'][0]}},[_vm._v("展示“填写二维码”")]),_c('icy-iphone')],1):_vm._e()],1):_vm._e(),_c('van-popup',{staticClass:"qrcode-popup-wrap",attrs:{"lazy-render":false},model:{value:(_vm.qrcodePopupShow),callback:function ($$v) {_vm.qrcodePopupShow=$$v},expression:"qrcodePopupShow"}},[_c('div',{staticClass:"dfc flex-center w100 bgfff qrcode-popup-box"},[_c('div',{ref:"qrCodeUrl",staticClass:"qrcode"}),_c('div',{staticClass:"fs34 qrcode-popup-title"},[_vm._v("扫码可以提交帮助给案主")])]),_c('div',{staticClass:"fs0 qrcode-popup-close",on:{"click":_vm.closeQrcodePopup}},[_c('img',{staticClass:"w100 h100",attrs:{"src":require("../../assets/images/popup-close.png")}})])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }