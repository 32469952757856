<template>
  <div id="app" class="fs24 fc333">
    <div slot="content">
      <keep-alive>
        <router-view
          v-if="$route.meta.keepAlive"
          :key="$route.fullPath"
        ></router-view>
      </keep-alive>
      <router-view
        v-if="!$route.meta.keepAlive"
        :key="$route.fullPath"
      ></router-view>
    </div>
    <sharing-guide></sharing-guide>
    <icy-back v-show="showBack"></icy-back>
  </div>
</template>
<script>
import { isWeiXinBrowser, isApplet } from "./utils/util";
import { wxShare } from "./utils/logon";
export default {
  data() {
    return {
      shareInfo: {
        title: "云派私董会",
        desc: "遇事不决开私董，这里能解决你的大部分困惑",
        longTitle: "云派私董会，遇事不决开私董，这里能解决你的大部分困惑",
        link: location.href.split("#")[0],
        /**
           * "http://web.marketinguniversity.cn/share-icon.png",
            "http://sidonghui.shifang.tech/share-icon.png"
           */
        imgUrl: [location.origin + "/share-icon.png"]
      },
      bottomTheme: "dark",
      showBack: false,
      hideBackPathnameList: [
        "login",
        "home",
        "hostRegister",
        "me",
        "resources",
        "square",
        "meetingDetail",
        "invite",
        "succeed",
        "perfect"
      ],
      specialShareList: [
        "meetingDetail",
        "meetingPaid",
        "recruitingSiDong",
        "sidongRing",
        "successCreate"
      ]
    };
  },
  watch: {
    $route(to) {
      if (isApplet()) {
        // 隐藏 hideBackPathnameList 中包含的页面
        if (this.hideBackPathnameList.indexOf(to.name) == -1) {
          this.showBack = true;
        } else {
          this.showBack = false;
        }

        //  除了特殊页面分享外，重置分享页为首页
        if (this.specialShareList.indexOf(to.name) == -1) {
          window.wx.miniProgram.postMessage({ data: this.shareInfo });
        }
      }
    }
  },
  created() {
    if (isApplet()) {
      this.shareInfo.title = "遇事不决开私董，这里能解决你的大部分困惑";
    }
    //
    const userId = parseInt(this.$route.query.userId) || null;
    if (userId) {
      this.$localStore.set(`userId`, userId);
    }
  },

  async mounted() {
    if (isWeiXinBrowser()) {
      this.shareConfig = await this.getShareConfig();

      this.$bus.$on("hasShare", shareInfo => {
        this.shareInit(shareInfo);
        if (isApplet()) {
          window.wx.miniProgram.postMessage({ data: shareInfo });
        }
      });
    }
    this.$bus.$on("changeQynStatus", () => {
      this.shareInfo.link = location.href.split("#")[0] + `#/home`;
    });
    this.shareInit(this.shareInfo);
  },
  methods: {
    // 获取分享config
    async getShareConfig() {
      const resp = await this.$API
        .post("shareConfig", { url: location.href.split("#")[0] }, false)
        .then(resp => resp)
        .catch(err => {
          this.$toast(err.msg);
        });
      if (resp.code == 1000) {
        return resp.data.jsConfig;
      }
    },
    /**
     * 分享
     */
    async shareInit(shareInfo = this.shareInfo) {
      const config = this.shareConfig;
      wxShare(config, shareInfo);
    }
  }
};
</script>

<style lang="less">
#app {
  width: 7.5rem;
  margin: 0 auto;
}
</style>
