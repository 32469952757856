<template>
  <div v-if="info" class="list">
    <div v-if="list.length" class="director-list">
      <div v-for="(item, index) in list" :key="index" class="director-item">
        <feeling-card
          :meetingId="id"
          :userInfo="userInfo"
          :info="item"
          :type="type + ''"
        ></feeling-card>
      </div>
    </div>
    <empty v-else></empty>
    <!-- // 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
    <!-- status 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成' -->
    <div
      v-if="info.status != 5"
      class="dfr cross-center main-between help-buttom-b"
    >
      <div
        v-if="!isCreated && info.status != 5 && info.status != 2"
        class="dfc flex-center"
        @click="createFeeling"
      >
        <icy-button
          height="0.8rem"
          border-radius="0.44rem"
          :width="[type == 1 && info.status != 5 ? '3rem' : '6.78rem'][0]"
          >创建我的参会感想</icy-button
        >
        <icy-iphone></icy-iphone>
      </div>
      <icy-bottom></icy-bottom>

      <!-- TODO 按钮展示二维码 -->
      <div
        v-if="type == 1 && info.status != 5"
        class="dfc flex-center"
        @click="openQrcodePopup"
      >
        <icy-button
          height="0.8rem"
          border-radius="0.44rem"
          :width="[isCreated || info.status == 2 ? '6.78rem' : '3rem'][0]"
          >展示“填写二维码”</icy-button
        >
        <icy-iphone></icy-iphone>
      </div>
    </div>

    <!-- 二维码 -->
    <van-popup
      v-model="qrcodePopupShow"
      :lazy-render="false"
      class="qrcode-popup-wrap"
    >
      <div class="dfc flex-center w100 bgfff qrcode-popup-box">
        <div class="qrcode" ref="qrCodeUrl"></div>
        <div class="fs34 qrcode-popup-title">扫码可以提交参会感想</div>
      </div>

      <div class="fs0 qrcode-popup-close" @click="closeQrcodePopup">
        <img src="../../assets/images/popup-close.png" class="w100 h100" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { isApplet } from "../../utils/util";
export default {
  props: {
    id: String,
    type: String,
    info: Object,
    userInfo: Object,
    user_id: String
  },
  data() {
    return {
      list: [],
      routerType: 0,
      isCreated: false,
      qrcode: "",
      // 二维码弹出层
      qrcodePopupShow: false,
      isScan: false,
      sendUserId: ""
    };
  },
  created() {
    this.routerType = parseInt(this.$route.query.type) || 0;
    this.isScan = parseInt(this.$route.query.scan) == 1 ? true : false;
    this.sendUserId = parseInt(this.$route.query.sendUserId) || "";
    this.initPage();
  },
  mounted() {
    this.$bus.$on("updateFeeling", () => {
      this.initPage();
    });
    this.creatQrCode();
  },
  methods: {
    // 创建二维码
    creatQrCode() {
      let reflectionsLink =
        location.origin +
        location.pathname +
        `#/meetingDetail?type=2&active=${parseInt(
          this.$route.query.active
        )}&id=${parseInt(this.id)}&scan=1&sendUserId=${this.user_id}`;
      if (isApplet() && this.$config.appletScanURL) {
        reflectionsLink = `${this.$config.appletScanURL}${encodeURIComponent(
          reflectionsLink
        )}`;
      }
      this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: reflectionsLink,
        width: 190,
        height: 190,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
      });
    },

    async initPage() {
      if (this.isScan) {
        const sidongList = await this.initSidongList();
        const userId = await this.initUserInfo();
        const isJoin = sidongList.some(item => item.user_id == userId);
        if (isJoin || parseInt(this.sendUserId) == parseInt(userId)) {
          this.$go("meetingDetail", {
            type: 0,
            active: `${parseInt(this.$route.query.active)}`,
            id: `${parseInt(this.id)}`
          });
        } else {
          this.$toast("您目前不是此会议的参会私董，无法操作!");
          this.$go("me");
        }
      }
      this.initList();
    },
    // 获取参会私董
    async initSidongList() {
      let list = [];
      const data = {
        id: this.id
      };
      const resp = await this.$API
        .get("joinMeetingSidongList", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        list = resp.data.list;
      }
      return list;
    },

    // 获取用户
    async initUserInfo() {
      let userId = "";
      const resp = await this.$API.get("userInfo").then(resp => resp);
      if (resp.code == 1000) {
        userId = resp.data.info.id;
      }
      return userId;
    },

    async initList() {
      const data = {
        id: this.id
      };
      const resp = await this.$API
        .get("joinFeelingList", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        let list = resp.data.list;
        if (list.length) {
          let isCreated = list.some(item => item.user.id == this.userInfo.id);
          this.isCreated = isCreated;
        }
        this.list = list;
      }
    },
    createFeeling() {
      this.$go("createFeeling", {
        id: this.id,
        type: this.routerType,
        isSidong: this.type == 2 ? 1 : 0
      });
    },
    // 打开二维码弹出层
    openQrcodePopup() {
      this.qrcodePopupShow = true;
    },
    // 关闭二维码弹出层
    closeQrcodePopup() {
      this.qrcodePopupShow = false;
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.list {
  min-height: calc(100vh - 44px - 1rem);
  background: #f7f7f7;
  padding-bottom: 1.8rem;
}
.help-buttom-b {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.2rem 0.36rem;
  background: #f7f7f7;
}

.qrcode-popup-wrap {
  background: transparent;
  overflow: hidden;
  .qrcode-popup-box {
    border-radius: 0.2rem;
    .qrcode {
      padding: 0.6rem 0.6rem 0.2rem;
    }
    .qrcode-popup-title {
      margin-bottom: 0.6rem;
    }
  }
  .qrcode-popup-close {
    background: transparent;
    width: 0.72rem;
    height: 0.74rem;
    margin: 0 auto;
    margin-top: 0.5rem;
  }
}
</style>
