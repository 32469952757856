<template>
  <div class="help-feeling dfc por">
    <!-- status  0 => "待付款"  1 => "已取消" 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成' -->
    <div
      v-if="!info.is_received && info.user.id == userInfo.id && status != 5"
      @click="goEditMyHelp"
      class="editor-img-box dfr "
    >
      <img src="../../../assets/images/editor.png" alt="" class="w100 h100" />
    </div>
    <div class="dfr main-between">
      <div class="dfr main-start">
        <!-- 头像 -->
        <div class="avatar-box">
          <img
            v-if="info.user.avatar"
            :src="info.user.avatar"
            alt=""
            class="w100 h100 object-cover"
          />
          <img
            v-else
            src="../../../assets/images/default-portrait.png"
            alt=""
            class="w100 h100 object-cover"
          />
          <div class="emblem-box" v-if="info.user.is_major_auth">
            <img
              src="../../../assets/images/home-emblem.png"
              alt=""
              class="w100 h100"
            />
          </div>
        </div>
        <!-- 信息 -->
        <div class="message-box">
          <div class="fs32 fw600 fc333">{{ info.user.name }}</div>
          <div class="job-box fs24 fw400 fc999">
            {{ info.user.job }}
          </div>
        </div>
      </div>
      <!-- 插槽 -->
      <div class="slot-box dfc flex-center">
        <slot></slot>
      </div>
    </div>

    <!-- 描述 -->
    <div class="dfc flex-center">
      <div
        class="describe-box fs28 fw400 fc666 dfr "
        :class="[isHidden ? 'hidden' : '']"
      >
        {{ info.content }}
      </div>
    </div>
    <!-- 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
    <!-- 分享/展开/收起 -->
    <div class="dfr cross-center">
      <!-- 展开/收起 -->
      <div
        v-show="info.content.length > 110 ? true : false"
        style="margin:0 auto"
      >
        <div
          class="isHidden-box fs24 fw400 fcb2 dfr cross-center"
          @click.capture="isHidden = !isHidden"
          v-if="isHidden"
        >
          展开 <van-icon name="arrow-down" class="van-icon" />
        </div>
        <div
          class="isHidden-box fs24 fw400 fcb2 dfr cross-center"
          @click.capture="isHidden = !isHidden"
          v-else
        >
          收起 <van-icon name="arrow-up" class="van-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meetingId: String,
    info: Object,
    type: String,
    userInfo: Object,
    status: Object
  },
  data() {
    return {
      isHidden: true
    };
  },
  created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    initPage() {},
    goEditMyHelp() {
      if (!this.info.is_received) {
        this.$go("editHelp", {
          meetingId: this.meetingId,
          id: this.info.id,
          type: this.type
        });
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.editor-img-box {
  position: absolute;
  max-width: 0.28rem;
  max-height: 0.28em;
  right: 0.2rem;
  top: 0.2rem;
}
.help-feeling {
  width: 6.94rem;
  background: #ffffff;
  border-radius: 0.12rem;
  margin-top: 0.28rem;
  .avatar-box {
    width: 0.96rem;
    height: 0.96rem;
    position: relative;
    margin: 0.36rem 0 0 0.36rem;
    img {
      border-radius: 50%;
    }
    .emblem-box {
      width: 0.28rem;
      height: 0.28rem;
      position: absolute;
      bottom: 0;
      right: 0;
      img {
        position: absolute;
      }
    }
  }
  .message-box {
    margin: 0.42rem 0 0 0.2rem;
    .job-box {
      margin-top: 0.12rem;
    }
  }
  .slot-box {
    margin-top: 0.36rem;
  }
  .describe-box {
    width: 6.22rem;
    margin: 0.26rem 0;
    word-break: break-word;
  }
  .hidden {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .share-img-box {
    margin-left: 0.36rem;
    margin-right: -2rem;
    .share-img {
      width: 0.32rem;
      height: 0.32rem;
      margin-bottom: 0.36rem;
      margin-right: 0.12rem;
    }
  }

  .van-icon {
    margin-left: 0.12rem;
  }
  .isHidden-box {
    margin-bottom: 0.36rem;
  }
}
</style>
