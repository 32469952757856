<template>
  <van-popup v-if="isWexin" v-model="show" position="top" @closed="closed">
    <img
      :class="[currentEnvISApplet ? 'applet' : '', 'share']"
      src="../assets/images/share-bg.png"
      alt=""
    />
  </van-popup>
  <van-popup v-else v-model="inviterBoxShow" class="share-url" lock-scroll>
    <div class="inviter-wrapper fs26">
      <van-icon
        class="close-icon"
        name="cross"
        color="#666"
        size="1.2em"
        @click="urlPopupClose"
      />
      <div class="title">邀请云派私董</div>
      <div class="content dfc">
        <button
          class="url tag-read fc333"
          v-text="invitationUrlInfo"
          :data-clipboard-text="invitationUrlInfo"
          @click="copyLink"
        ></button>
        <span class="text fs24 fc666">点击复制上述链接，分享给云派私董</span>
        <span class="tip fs24 fc999">Tip: 点击网址即可复制</span>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { isWeiXinBrowser, isApplet } from "../utils/util";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      currentEnvISApplet: false,
      show: false,
      isWexin: true,
      inviterBoxShow: false,
      invitationUrlInfo: ""
    };
  },
  created() {
    this.isWexin = isWeiXinBrowser();
  },
  mounted() {
    this.currentEnvISApplet = isApplet();
    this.$bus.$on("showShareGuide", _show => {
      if (this.isWexin || isApplet()) {
        this.show = _show;
      } else {
        this.inviterBoxShow = _show;
      }
    });

    this.$bus.$on("shareGuideHref", url => {
      this.invitationUrlInfo = url;
    });
  },
  methods: {
    closed() {
      this.$bus.$emit("shareGuideClosed");
    },
    copyLink() {
      const clipboard = new Clipboard(".tag-read");
      clipboard.on("success", () => {
        // 用vant轻提示
        this.$toast("复制成功");
        this.$bus.$emit("shareGuideClosed");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$toast("复制失败");
        // 释放内存
        clipboard.destroy();
      });
    },
    urlPopupClose() {
      this.inviterBoxShow = false;
    }
  }
};
</script>
<style lang="less" scoped>
.van-popup {
  background: transparent;
  padding: 0.2rem 0.5rem 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.wrap {
  z-index: 999 !important;
}

.share {
  width: 3.53rem;
  &.applet {
    margin-right: 0.66rem;
  }
}

.share-url {
  background: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 0.2rem;
}

.van-overlay {
  right: 0;
  bottom: 0;
}

.inviter-wrapper {
  .title {
    color: #cb9025;
    font-size: 0.28rem;
    text-align: center;
  }

  .content {
    margin: 0.4rem 0 0.2rem;
    width: 260px;

    .url {
      border: none;
      outline: none;
      text-align: left;
      background: #fff;
      padding: 0;
      text-decoration: underline;
      line-height: 1.4;
      word-break: break-all;

      &:active {
        opacity: 0.7;
      }
    }

    .text {
      margin: 0.3rem 0 0.2rem;
    }
  }
}

.close-icon {
  float: right;
  position: absolute;
  right: 0.3rem;

  &:after {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
